import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TriangleComponent } from '@x/ecommerce-shop/app/core/shop-ui/components/overlay/triangle/triangle.component';
import { OverlayContentComponent } from './overlay-content/overlay-content.component';

@NgModule({
  declarations: [TriangleComponent, OverlayContentComponent],
  exports: [TriangleComponent, OverlayContentComponent],
  imports: [CommonModule],
})
export class OverlayUiModule {}
