import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AssociationService } from '@x/ecommerce-shop/app/core/association/services/association.service';
import { IShopProductItem, TShopAssociationWithProducts } from '@x/ecommerce/shop-client';

@Component({
  selector: 'x-association-add-to-bag',
  templateUrl: './association-add-to-bag.component.html',
  styleUrls: ['./association-add-to-bag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociationAddToBagComponent {
  @Input() associationWithProducts: TShopAssociationWithProducts | null | undefined;

  constructor(private associationService: AssociationService) {}

  isVisible(
    visible: boolean,
    product: IShopProductItem,
    association: TShopAssociationWithProducts,
  ) {
    this.associationService.sendEvent(visible, product, association, 'add-to-bag-recommendation');
  }
}
