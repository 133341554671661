import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Operation, OperationObserverService } from '@x/common/operation';
import { TAssociationOrigin } from '@x/common/tracking';
import { AddToCartService } from '@x/ecommerce-shop/app/core/order/services/add-to-cart.service';
import { TAvailabilityStatus } from '@x/ecommerce-shop/app/core/product/components/variant-availability/variant-availability';
import { ProductListItemService } from '@x/ecommerce-shop/app/core/product/services/product-list-item.service';
import { IProductItem } from '@x/ecommerce-shop/app/core/product/types/product-item';
import { ButtonSizes } from '@x/ecommerce-shop/app/core/utils/directives/button-size.directive';
import { IShopProductItem } from '@x/ecommerce/shop-client';
import { Observable } from 'rxjs';

@Component({
  selector: 'x-association-order-item',
  templateUrl: './association-order-item.component.html',
  styleUrls: ['./association-order-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'x-association-order-item' },
})
export class AssociationOrderItemComponent {
  productView$: Observable<IProductItem>;

  @Input({ required: true }) associationId: number;
  @Input({ required: true }) origin: TAssociationOrigin;

  @Input() set product(product: IShopProductItem) {
    this.productView$ = this.productItemViewService.getView$(product);
  }

  @Input() notify: boolean;
  @Input() buttonSize: ButtonSizes;
  @Input() buttonColor: ThemePalette;

  addToCartControl$ = this.operationObserverService.control(
    (args: { quantity: number; availability: TAvailabilityStatus; product: IShopProductItem }) => {
      const { quantity, availability, product } = args;

      return this.addToCartService.addProduct(
        product,
        {
          quantity,
          availabilityStatus: availability,
          notify: this.notify,
        },
        {
          associationId: this.associationId,
          origin: this.origin,
        },
      );
    },
  );

  constructor(
    private operationObserverService: OperationObserverService,
    private addToCartService: AddToCartService,
    private productItemViewService: ProductListItemService,
  ) {}

  async addToCart(
    quantity: number,
    availability: TAvailabilityStatus,
    product: IShopProductItem,
    operation: Operation,
  ) {
    if (operation.status === 'loading') return;
    await this.addToCartControl$.activate({ quantity, availability, product });
  }
}
