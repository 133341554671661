<ng-container *ngIf="operationState$ | async as operation" [xOperation]="operation">
  <x-page-panel *xOperationLoading>
    <x-ghost-title class="mb-2" size="large"></x-ghost-title>
    <x-ghost-product-list [columns]="ghostProducts$ | async"></x-ghost-product-list>
  </x-page-panel>

  <ng-container *ngIf="operation.data as products">
    <x-page-panel *ngIf="products.length" [heading]="title">
      <x-product-swiper-slider
        #PRODUCT_SWIPER_SLIDER
        [swiperOptions]="swiperConfig"
        [products]="products"
        [listName]="title"
        [loading]="loadMoreProducts$ | async"
        [associationId]="associationId"
        associationOrigin="product-detail-recommendation"
        origin="product-detail-recommendation"
        [moreProducts]="moreProducts$ | async"
        (onSlideChange)="onSlideChange($event)"
        (onNextClickEvent)="onNextClickEvent($event)"
      ></x-product-swiper-slider>
    </x-page-panel>
  </ng-container>
</ng-container>
