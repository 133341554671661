import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppFeatureValueType } from '@x/common/ab-test/types';
import { CheckoutOrderState } from '@x/ecommerce-shop/app/checkout/state/checkout-order.state';
import { IAssociationCmp } from '@x/ecommerce-shop/app/core/association/association';
import { AssociationService } from '@x/ecommerce-shop/app/core/association/services/association.service';
import { TShopAssociationWithProducts } from '@x/ecommerce/shop-client';
import { ProductAssociationTarget } from '@x/schemas/ecommerce';
import { Observable, switchMap } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Component({
  selector: 'x-association-checkout',
  templateUrl: './association-checkout.component.html',
  styleUrls: ['./association-checkout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociationCheckoutComponent implements IAssociationCmp, OnInit {
  _testCase: string[] | null = null;

  @Input() set testCase(test: AppFeatureValueType) {
    if (typeof test === 'string' && test.length > 0) {
      this._testCase = [test];
      return;
    }
  }

  swiperConfig: SwiperOptions = {
    slidesPerView: 3,
    spaceBetween: 15,
    breakpoints: {
      0: { slidesPerView: 2.15, shortSwipes: true, spaceBetween: 10 },
      576: { slidesPerView: 2.15, shortSwipes: false, spaceBetween: 10 },
      768: { slidesPerView: 3.15, shortSwipes: false, spaceBetween: 15 },
    },
  };

  association$?: Observable<TShopAssociationWithProducts | null>;

  constructor(
    private store: Store,
    private associationService: AssociationService,
  ) {}

  ngOnInit(): void {
    this.association$ = this.store.select(CheckoutOrderState.order).pipe(
      startWith(null),
      switchMap((order) =>
        this.associationService.getOrderAssociation$(
          order,
          ProductAssociationTarget.Checkout,
          this._testCase,
          9,
        ),
      ),
    );
  }
}
