import {
  Directive,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ABTestService } from '@x/common/ab-test/ab-test.service';
import { TFeatureTestType } from '@x/common/ab-test/types';
import { IShopConfig, SHOP_CONFIG } from '@x/ecommerce-shop/config/shop.config';
import { Subscription } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[xABFeatureIf]',
})
export class AbTestDirective implements OnDestroy, OnInit {
  private subscription = new Subscription();

  @Input('xABFeatureIf') feature?: TFeatureTestType;

  @Input('xABFeatureIfElse')
  else: TemplateRef<any>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private abTestService: ABTestService,
    @Inject(SHOP_CONFIG) private shopConfig: IShopConfig,
  ) {}

  ngOnInit(): void {
    this.viewContainer.clear();

    const featureConfig = this.feature && this.shopConfig.abTestConfig?.features?.[this.feature];

    if (!featureConfig) {
      return this.doElse();
    }

    const { name, options, value: checkForValue } = featureConfig;

    this.subscription.add(
      this.abTestService.observeFeature$(name, options).subscribe((featureValue) => {
        let shouldCreate: boolean;

        if (typeof featureValue === 'boolean') {
          shouldCreate = featureValue;
        } else if (options?.showByDefault) {
          shouldCreate = true;
        } else if (featureValue === null && options?.allowNull) {
          shouldCreate = true;
        } else if (checkForValue !== undefined) {
          shouldCreate = featureValue === checkForValue;
        } else shouldCreate = !!featureValue;

        if (shouldCreate) {
          this.viewContainer.createEmbeddedView(this.templateRef, { $implicit: featureValue });
          return;
        }

        this.doElse();
      }),
    );
  }

  private doElse() {
    if (this.else) this.viewContainer.createEmbeddedView(this.else);
    else this.viewContainer.clear();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
