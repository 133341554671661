import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'x-triangle',
  templateUrl: './triangle.component.html',
  styleUrls: ['./triangle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriangleComponent implements OnInit {
  @Input() target: CdkOverlayOrigin;

  @Input() default: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.snapToOrigin();
  }

  ngOnInit(): void {
    if (!this.target) throw new Error('Input target required');
    this.snapToOrigin();
  }

  snapToOrigin() {
    if (!this.target) return;

    const triangleLeft =
      this.target.elementRef.nativeElement.getBoundingClientRect().left +
      this.target.elementRef.nativeElement.clientWidth / 2 -
      5;

    const triagleTop =
      this.target.elementRef.nativeElement.getBoundingClientRect().top +
      this.target.elementRef.nativeElement.clientHeight;

    this.left = `${Math.ceil(triangleLeft)}px`;
    this.top = `${Math.ceil(triagleTop)}px`;
  }

  @HostBinding('style.left')
  left: string = '0px';

  @HostBinding('style.top')
  top: string = '0px';
}
