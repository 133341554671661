import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { ObserveVisibilityDirective } from '@x/common/browser/directives/observe-visibility.directive';
import { MediaThumbnailComponent } from '@x/common/media-thumbnail/media-thumbnail.component';
import { MoneyModule } from '@x/common/money';
import { OperationModule } from '@x/common/operation';
import { SwiperSlideComponent } from '@x/common/swiper/swiper-slide/swiper-slide.component';
import { SwiperComponent } from '@x/common/swiper/swiper.component';
import { TrackingProductClickDirective } from '@x/common/tracking/directives/tracking-product-click.directive';
import { AssociationCheckoutComponent } from '@x/ecommerce-shop/app/core/association/components/association-checkout/association-checkout.component';
import { AssociationMiniBagComponent } from '@x/ecommerce-shop/app/core/association/components/association-mini-bag/association-mini-bag.component';
import { AssociationOrderItemComponent } from '@x/ecommerce-shop/app/core/association/components/association-order-item/association-order-item.component';
import { AssociationProductDetailComponent } from '@x/ecommerce-shop/app/core/association/components/association-product-detail/association-product-detail.component';
import { AssociationService } from '@x/ecommerce-shop/app/core/association/services/association.service';
import { ProductModule } from '@x/ecommerce-shop/app/core/product/product.module';
import { ShopErrorModule } from '@x/ecommerce-shop/app/core/shop-error/shop-error.module';
import { AddToCartButtonModule } from '@x/ecommerce-shop/app/core/shop-ui/components/add-to-cart-button/add-to-cart-button.module';
import { GhostAddToCartModule } from '@x/ecommerce-shop/app/core/shop-ui/components/ghost-loading/ghost-add-to-cart';
import { GhostParagraphModule } from '@x/ecommerce-shop/app/core/shop-ui/components/ghost-loading/ghost-paragraph';
import { GhostProductListComponent } from '@x/ecommerce-shop/app/core/shop-ui/components/ghost-loading/ghost-product-list/ghost-product-list.component';
import { GhostTitleModule } from '@x/ecommerce-shop/app/core/shop-ui/components/ghost-loading/ghost-title';
import { PageLayoutModule } from '@x/ecommerce-shop/app/core/shop-ui/components/layout/page-layout';
import { UrlModule } from '@x/ecommerce-shop/app/core/url/url.module';
import { UtilsModule } from '@x/ecommerce-shop/app/core/utils/utils.module';
import { GhostProductListingModule } from 'apps/ecommerce-shop/src/app/core/shop-ui/components/ghost-loading/ghost-product-card';
import { GhostProductMediaModule } from 'apps/ecommerce-shop/src/app/core/shop-ui/components/ghost-loading/ghost-product-media';
import { AssociationAddToBagComponent } from './components/association-add-to-bag/association-add-to-bag.component';

@NgModule({
  providers: [AssociationService],
  declarations: [
    AssociationProductDetailComponent,
    AssociationMiniBagComponent,
    AssociationOrderItemComponent,
    AssociationCheckoutComponent,
    AssociationAddToBagComponent,
  ],
  imports: [
    CommonModule,
    OperationModule,
    PageLayoutModule,
    GhostTitleModule,
    GhostProductListingModule,
    GhostProductMediaModule,
    GhostParagraphModule,
    AddToCartButtonModule,
    MoneyModule,
    MatButtonModule,
    UrlModule,
    RouterLink,
    ShopErrorModule,
    GhostAddToCartModule,
    TrackingProductClickDirective,
    UtilsModule,
    SwiperComponent,
    ProductModule,
    MediaThumbnailComponent,
    SwiperSlideComponent,
    GhostProductListComponent,
    ObserveVisibilityDirective,
  ],
  exports: [
    AssociationMiniBagComponent,
    AssociationProductDetailComponent,
    AssociationCheckoutComponent,
    AssociationAddToBagComponent,
  ],
})
export class AssociationModule {}
