import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GhostProductListingModule } from '@x/ecommerce-shop/app/core/shop-ui/components/ghost-loading/ghost-product-card';
import { UtilsModule } from '@x/ecommerce-shop/app/core/utils/utils.module';

@Component({
  selector: 'x-ghost-product-list',
  standalone: true,
  imports: [AsyncPipe, GhostProductListingModule, NgForOf, NgIf, UtilsModule],
  templateUrl: './ghost-product-list.component.html',
  styleUrl: './ghost-product-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GhostProductListComponent {
  _columns = new Array(3);
  _rows = new Array(1);

  @Input() set rows(rows: number) {
    this._rows = new Array(rows);
  }

  @Input() set columns(columns: number | null) {
    if (!columns) return;

    this._columns = new Array(columns);
  }

  get colsArr() {
    return this._columns;
  }

  get rowsArr() {
    return this._rows;
  }
}
