import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppFeatureValueType } from '@x/common/ab-test/types';
import { IAssociationCmp } from '@x/ecommerce-shop/app/core/association/association';
import { AssociationService } from '@x/ecommerce-shop/app/core/association/services/association.service';
import { ShopOrderState } from '@x/ecommerce-shop/app/core/order/state/shop-order.state';
import { IShopProductItem, TShopAssociationWithProducts } from '@x/ecommerce/shop-client';
import { ProductAssociationTarget } from '@x/schemas/ecommerce';
import { Observable, switchMap } from 'rxjs';

@Component({
  selector: 'x-association-mini-bag',
  templateUrl: './association-mini-bag.component.html',
  styleUrls: ['./association-mini-bag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociationMiniBagComponent implements IAssociationCmp, OnInit {
  _testCase: string[] | null;
  @Input() set testCase(test: AppFeatureValueType) {
    if (typeof test === 'string' && test.length > 0) {
      this._testCase = [test];
      return;
    }
  }

  association$?: Observable<TShopAssociationWithProducts | null>;

  constructor(
    private store: Store,
    private associationService: AssociationService,
  ) {}

  ngOnInit(): void {
    this.association$ = this.store
      .select(ShopOrderState.order)
      .pipe(
        switchMap((order) =>
          this.associationService.getOrderAssociation$(
            order,
            ProductAssociationTarget.Cart,
            this._testCase,
          ),
        ),
      );
  }

  isVisible(
    visible: boolean,
    product: IShopProductItem,
    association: TShopAssociationWithProducts,
  ) {
    this.associationService.sendEvent(visible, product, association, 'mini-bag-recommendation');
  }
}
