<div class="association" *ngIf="association$ | async as association">
  <h4 class="mb-2 text-truncate">{{ association.title }}</h4>

  <div class="products d-flex gap-2 flex-column">
    <x-association-order-item
      *ngFor="let product of association.products"
      [product]="product"
      [notify]="false"
      buttonColor="accent"
      buttonSize="small"
      [associationId]="association.id"
      origin="mini-bag-recommendation"
      xObserveVisibility
      [emitVisibleOnce]="true"
      (isVisible)="isVisible($event, product, association)"
    ></x-association-order-item>
  </div>
</div>
