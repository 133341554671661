<ng-container *ngIf="addToCartControl$ | async as operation" [xOperation]="operation">
  <div class="product" *ngIf="productView$ | async as productView">
    <x-media-thumbnail
      [url]="productView.mainMedia?.url"
      [width]="65"
      [height]="65"
      crop="fit"
      [routerLink]="productView.detailUrl"
      [queryParams]="{ associationId, origin }"
      [xTrackingProductClick]="productView"
    ></x-media-thumbnail>

    <div class="content overflow-hidden">
      <div class="text-truncate">{{ productView.title }}</div>
      <div class="mat-body-1">
        {{ productView.variation === 'OPTION' ? 'From ' : '' }}
        {{
          productView.variantAvailability.price.amount
            | money: productView.variantAvailability.price.currency
        }}
      </div>
    </div>

    <ng-container *ngIf="productView.variation === 'SINGLE'">
      <x-add-to-cart-button
        *ngIf="productView.variantAvailability as availability"
        class="cta"
        [buttonSize]="buttonSize"
        [buttonColor]="buttonColor"
        [label]="availability.reason?.label ?? 'Add'"
        [disabled]="availability.status === 'UNAVAILABLE'"
        [disabledTooltip]="availability.reason?.description"
        [quantity]="availability.quantity"
        [loading]="operation.state.status === 'loading'"
        [stackQuantity]="false"
        (quantityChanged)="addToCart($event, availability.status, productView.product, operation)"
        mode="no-controls"
      ></x-add-to-cart-button>
    </ng-container>

    <button
      class="cta"
      *ngIf="productView.variation === 'OPTION'"
      mat-flat-button
      color="primary"
      [routerLink]="productView.detailUrl"
      [xButtonSize]="buttonSize"
      [color]="buttonColor"
      [queryParams]="{ associationId, origin }"
    >
      Options
    </button>
  </div>
  <x-shop-error-operation *xOperationError="let error" [error]="error"></x-shop-error-operation>
</ng-container>
