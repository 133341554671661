<div class="association" *ngIf="association$ | async as association">
  <h3 class="mb-3 text-truncate">{{ association.title }}</h3>

  <x-swiper
    [slideTemplate]="itemTemplate"
    [slides]="association.products"
    [swiperOptions]="swiperConfig"
  >
    <ng-template #itemTemplate let-product>
      <x-product-card
        [product]="product"
        addToTarget="Checkout"
        [associationId]="association.id"
        associationOrigin="checkout-recommendation"
        [listName]="association.title"
        origin="checkout-recommendation"
      ></x-product-card>
    </ng-template>
  </x-swiper>
</div>
