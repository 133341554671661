<ng-container *ngIf="associationWithProducts && associationWithProducts.products.length">
  <div class="association">
    <h4 class="mb-2 text-truncate">{{ associationWithProducts.title }}</h4>

    <div class="products d-flex gap-2 flex-column">
      <x-association-order-item
        *ngFor="let product of associationWithProducts.products"
        [product]="product"
        buttonColor="accent"
        buttonSize="small"
        origin="add-to-bag-recommendation"
        [associationId]="associationWithProducts.id"
        xObserveVisibility
        [emitVisibleOnce]="true"
        (isVisible)="isVisible($event, product, associationWithProducts)"
      ></x-association-order-item>
    </div>
  </div>

  <ng-content select="[xLoader]"></ng-content>
</ng-container>
