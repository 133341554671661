import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { OperationInitialState, OperationObserverService } from '@x/common/operation';
import { ResponsiveService } from '@x/common/responsive';
import { ChannelContextService } from '@x/ecommerce-shop/app/channel/services/channel-context.service';
import { AssociationService } from '@x/ecommerce-shop/app/core/association/services/association.service';
import { ProductSwiperSliderComponent } from '@x/ecommerce-shop/app/core/product/components/product-swiper-slider/product-swiper-slider.component';
import { PRODUCT_SWIPER_OPTIONS_BREAKPOINTS } from '@x/ecommerce-shop/app/core/product/components/product-swiper-slider/swiper';
import { IShopProductItem, TShopAssociatedProductsQueryVariables } from '@x/ecommerce/shop-client';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { Swiper } from 'swiper/types';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Component({
  selector: 'x-association-product-detail',
  templateUrl: './association-product-detail.component.html',
  styleUrls: ['./association-product-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociationProductDetailComponent implements OnChanges {
  index: number = 0;
  private query$ = new BehaviorSubject<TShopAssociatedProductsQueryVariables | null>(null);
  private stopLoadingMoreProducts = false;
  private swiperClickedNext = false;

  @Input() productId?: number;
  @Input() excludeProductIds: number[] = [];
  @Input() associationId?: number;
  @Input() title?: string;
  @Input() pageSize: number = 9;

  operationState$ = this.query$.pipe(
    filter((query) => Boolean(query)),
    map((query) => query as TShopAssociatedProductsQueryVariables),
    switchMap((query) => this.operationService.observe(this.fetchProducts$(query))),
    startWith(new OperationInitialState()),
  );

  ghostProducts$: Observable<number> = this.responsiveService
    .observeIsBreakpoint({ min: 'LG' })
    .pipe(
      map((isDesktop) => {
        return isDesktop ? 4 : 2;
      }),
    );

  swiperConfig: SwiperOptions = {
    slidesPerView: 4,
    breakpoints: {
      ...PRODUCT_SWIPER_OPTIONS_BREAKPOINTS,
      992: { slidesPerView: 4, shortSwipes: false },
      1200: { slidesPerView: 4, shortSwipes: false },
    },
  };

  @ViewChild('PRODUCT_SWIPER_SLIDER') productSwiperSlider: ProductSwiperSliderComponent;
  get swiperComponent() {
    return this.productSwiperSlider.swiperComponent;
  }

  get swiper() {
    return this.productSwiperSlider.swiperComponent.swiper;
  }

  moreProducts$ = new BehaviorSubject<IShopProductItem[]>([]);
  loadMoreProducts$ = new BehaviorSubject(false);

  constructor(
    private associationService: AssociationService,
    private channelContextService: ChannelContextService,
    private responsiveService: ResponsiveService,
    @Inject(LOCALE_ID) private locale: string,
    private operationService: OperationObserverService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.associationId && this.productId && this.channelContextService.channelCode) {
      this.query$.next({
        productIds: this.productId,
        associationId: this.associationId,
        channelCode: this.channelContextService.channelCode,
        locale: this.locale,
        excludeProductIds: this.excludeProductIds,
        page: {
          index: this.index,
          size: this.pageSize,
        },
      });
    }
  }

  async onSlideChange(swiper: Swiper) {
    await this.loadMoreProducts(swiper);
  }

  onNextClickEvent($event: Swiper) {
    this.swiperClickedNext = true;
  }

  private nextPageQuery(size?: number): TShopAssociatedProductsQueryVariables | null {
    const query = this.query$.getValue();
    if (!query) return null;

    this.index++;

    return {
      ...query,
      page: {
        ...query.page,
        ...(size && { size }),
        index: this.index,
      },
    };
  }

  private fetchProducts$(
    query: TShopAssociatedProductsQueryVariables,
  ): Observable<IShopProductItem[]> {
    return this.associationService.getAssociatedProducts$(query);
  }

  private async loadMoreProducts(swiper: Swiper) {
    if (
      this.stopLoadingMoreProducts ||
      !this.swiperClickedNext ||
      !swiper.isEnd ||
      !this.swiperComponent
    )
      return;

    const query = this.nextPageQuery();
    if (!query) return;

    this.loadMoreProducts$.next(true);
    const moreProducts = await firstValueFrom(this.fetchProducts$(query));
    this.moreProducts$.next(moreProducts);
    this.stopLoadingMoreProducts = moreProducts.length === 0 || moreProducts.length < this.pageSize;
    this.swiperClickedNext = false;
    this.loadMoreProducts$.next(false);
  }
}
